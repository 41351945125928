import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const defaultConfig = {
      sortField: {
        field: "text",
        direction: "asc"
      }
    };

    if (this.element.id === 'cta-icon-select') {
      defaultConfig.render = {
        option: function (data, escape) {
          return `<div>
                    <i class="fa fa-duotone fa-${escape(data.value)} mr-2"></i>
                    <span>${escape(data.text)}</span>
                  </div>`;
        },
        item: function (data, escape) {
          return `<div>
                    <i class="fa fa-duotone fa-${escape(data.value)} mr-2"></i>
                    <span>${escape(data.text)}</span>
                  </div>`;
        }
      };
    }

    const tomSelectInstance = new TomSelect(this.element, defaultConfig);

    if (Object.keys(tomSelectInstance.options).length > 6) {
      tomSelectInstance.dropdown_content.classList.add('ts-scrollable-dropdown');
    }
  }
}

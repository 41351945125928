import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
  ]

  navigate(event) {
    const url = event.target.value;
    if (url) {
      window.location.href = url;
    }
  }

  pickADate = () => {
    this.formTarget.requestSubmit();
  }

  openLink(event) {
    const url = event.currentTarget.dataset.url;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  toggleShowCommittees({ currentTarget }) {
    const parentElement = currentTarget.parentElement;
    const checkBoxWrapperElements = parentElement.querySelectorAll('.committee-tile');
    const itemsCount = currentTarget.dataset.itemsCount - 6;
    const parentContainer = currentTarget.closest('.committee-type-committees');

    if (currentTarget.classList.contains('show-less')) this.scrollToElement(parentContainer);
    $(checkBoxWrapperElements).toggleClass('committee-tile-toggle');
    this.handleToggleShowFilterOptionsBtnText(parentElement, currentTarget, itemsCount);
  }

  handleToggleShowFilterOptionsBtnText(elem, showFiltersBtn, itemsCount) {
    const lessCheckBoxWrapperElements = elem.querySelectorAll('.committee-tile-toggle');
    const btnIcon = showFiltersBtn.querySelector('.show-all-committees-icon');
    const btnText = showFiltersBtn.querySelector('.show-all-committees-text');

    if (lessCheckBoxWrapperElements.length > 0) {
      if (btnIcon?.children[0]) {
        btnIcon.children[0].classList.replace('fa-minus', 'fa-plus');
      }
      if (btnText) {
        btnText.textContent = `Show all (${itemsCount})`;
        showFiltersBtn.classList.remove('show-less');
      }
    } else {
      if (btnIcon?.children[0]) {
        btnIcon.children[0].classList.replace('fa-plus', 'fa-minus');
      }
      if (btnText) {
        btnText.textContent = 'Show less';
        showFiltersBtn.classList.add('show-less');
      }
    }
  }


  scrollToElement = (selector) => {
    let targetOffset = $(selector).offset().top;

    window.scrollTo({
      top: targetOffset,
    });
  }
}

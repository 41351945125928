import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(".owl-carousel.scheduled-hearing-items .owl-nav").hide();

    $(".owl-carousel.scheduled-hearing-items").owlCarousel({
      nav: true,
      dots: false,
      margin: 24,
      navText: [
        `<div class='owl-nav-btn d-flex align-items-center justify-content-center'> <i class="fa-regular fa-angle-left"></i> </div>`,
        `<div class='owl-nav-btn d-flex align-items-center justify-content-center'> <i class="fa-regular fa-angle-right"></i> </div>`
      ],

      onRefreshed: function() {
        if (this.items().length < 5 && !this.settings.center) {
          $(".owl-stage-outer").addClass('d-flex justify-content-center');
        }
      },

      responsive: {
        0: {
          items: 2,
          center: true,
          margin: 100,
          slideBy: 1,
        },
        360: {
          items: 2,
          center: true,
          margin: 80,
          slideBy: 1,
        },
        400: {
          items: 2,
          center: true,
          margin: 70,
          slideBy: 1,
        },
        450: {
          items: 2,
          center: true,
          margin: 0,
          slideBy: 1,
        },
        650: {
          items: 3,
          margin: 5,
          slideBy: 1,
        },
        985: {
          items: 4,
          slideBy: 1
        },
        992: {
          items: 3,
          slideBy: 1
        },
        1090: {
          items: 4,
          margin: 0,
          slideBy: 1
        },
        1435: {
          items: 5,
          margin: 0,
          slideBy: 1
        },
      }
    });
  }
}
